import { red } from '@mui/material/colors';
import { createTheme } from '@mui/material/styles';

// A custom theme for this app
const theme = createTheme({
	palette: {
		mode: 'light',
		primary: {
			main: '#034b81',
		},
		secondary: {
			main: '#9bb649',
		},
		error: {
			main: '#d50000',
		},
		warning: {
			main: '#d81b60',
		},
		info: {
			main: '#2196f3',
		},
		success: {
			main: '#4caf50',
		},
		background: {
			default: '#f0f1ec',
		},
		text: {
			primary: 'rgba(3,75,129,0.9)',
			secondary: '#72736e',
			disabled: 'rgba(114,115,110,0.9)',
		},
	},

	typography: {
		fontFamily: 'Asap',
		// htmlFontSize: 22,
		fontSize: 8, // use this to change fontsize across the whole site
		h1: {
			// fontSize: '3.3rem',
			color: '#034b81',
		},
		h2: {
			// fontSize: '2.6rem',
			color: '#034b81',
			paddingBottom: '1.8rem',
		},
		h3: {
			color: '#034b81',
			paddingBottom: '1.8rem',
		},
		h4: {
			color: '#034b81',
			paddingBottom: '1.8rem',
		},
		body1: {
			fontSize: '1.2rem',
			fontWeight: 300,
			lineHeight: 1.6,
			color: '#72736e',
			paddingBottom: '1rem',
		},
	},
	shape: {
		borderRadius: 4,
	},
	components: {
		MuiAppBar: {
			styleOverrides: {
				root: {
					backgroundColor: '#f0f1ec',
					color: '#fff',
				},
			},
			// defaultProps: {
			// 	color: '#f0f1ec',
			// },
		},
		MuiToolbar: {
			styleOverrides: {
				root: {},
			},
		},
		MuiButton: {
			styleOverrides: {
				root: {
					fontSize: '1rem',
					color: '#ffffff',
				},
			},
		},
	},
	// MuiCard: {
	// 	styleOverrides: {
	// 		root: {
	// 			'&.MuiPostCard--02': {
	// 				borderRadius: '16px',
	// 				transition: '0.3s',
	// 				boxShadow: '0px 14px 80px rgba(34, 35, 58, 0.2)',
	// 				position: 'relative',
	// 				maxWidth: 800,
	// 				marginLeft: 'auto',
	// 				overflow: 'initial',
	// 				background: '#ffffff',
	// 				display: 'flex',
	// 				flexDirection: 'column',
	// 				alignItems: 'center',
	// 				padding: `16px 0`,
	// 				'&:hover': {
	// 					transform: 'translateY(-3px)',
	// 					boxShadow: '0 4px 20px 0 rgba(0,0,0,0.12)',
	// 				},
	// 				// [breakpoints.up('sm')]: {
	// 				// 	flexDirection: 'row',
	// 				// 	width: '95%',
	// 				// },
	// 				'& .MuiCardMedia-root': {
	// 					flexShrink: 0,
	// 					position: 'relative',
	// 					width: '80%',
	// 					maxWidth: 256,
	// 					marginTop: '-16%',
	// 					paddingTop: '48%',
	// 					boxShadow: '4px 4px 20px 1px rgba(252, 56, 56, 0.2)',
	// 					borderRadius: '16px', // 16px
	// 					backgroundSize: 'contain',
	// 					backgroundImage: 'linear-gradient(147deg, #fe8a39 0%, #fd3838 74%)',
	// 					backgroundColor: '#ffffff',
	// 					overflow: 'hidden',
	// 					// [breakpoints.up('sm')]: {
	// 					// 	width: '40%',
	// 					// 	marginTop: 0,
	// 					// 	marginLeft: '-8%',
	// 					// 	backgroundSize: 'cover',
	// 					// },
	// 					'&:after': {
	// 						content: '" "',
	// 						position: 'absolute',
	// 						top: 0,
	// 						left: 0,
	// 						width: '100%',
	// 						height: '100%',
	// 						backgroundImage: 'linear-gradient(147deg, #fe8a39 0%, #fd3838 74%)',
	// 						borderRadius: '16px', // 16
	// 						opacity: 0.5,
	// 					},
	// 				},
	// 				'& .MuiCardContent-root': {
	// 					textAlign: 'center',
	// 					padding: '16px',
	// 					// [breakpoints.up('sm')]: {
	// 					// 	paddingLeft: spacing(3),
	// 					// 	textAlign: 'left',
	// 					// },
	// 				},
	// 				'& .MuiTypography--heading': {
	// 					fontWeight: 'bold',
	// 				},
	// 				'& .MuiTypography--subheading': {
	// 					marginBottom: '16px',
	// 				},
	// 				'& .MuiButton--readMore': {
	// 					backgroundImage: 'linear-gradient(147deg, #fe8a39 0%, #fd3838 74%)',
	// 					boxShadow: '0px 4px 32px rgba(252, 56, 56, 0.4)',
	// 					borderRadius: 100,
	// 					paddingLeft: 24,
	// 					paddingRight: 24,
	// 					color: '#ffffff',
	// 				},
	// 			},
	// 		},
	// 	},
	// },
});

export default theme;